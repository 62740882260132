import { FilterList, FilterListItem, FilterLiveSearch, useGetList } from 'react-admin'
import { Card, CardContent } from '@mui/material'
import inMemoryJWT from '../../../auth/inMemoryJWT'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

export const TareFilter = () => {
  const { data: categoriesList } = useGetList(
    'categories',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'categoryName', order: 'ASC' },
    },
    { enabled: !!inMemoryJWT.getToken() },
  )

  return (
    <Card sx={{ order: -1, mr: 2, mt: 6, width: 220 }}>
      <CardContent>
        <FilterLiveSearch label="Штрихкод" />
        <FilterList label="Категория" icon={<PlaylistAddCheckIcon />}>
          {categoriesList &&
            categoriesList.map((item: any) => (
              <FilterListItem
                label={item.name}
                value={{
                  categoryId: item.id,
                }}
                // isSelected={isSelected}
                key={item.id}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  )
}
