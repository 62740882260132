import { BooleanField, Datagrid, EditButton, List, ReferenceField, TextField } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'
import { TareFilter } from './filters/TareFilter'

export const TareList = () => {
  return (
    <List
      title="Список тары"
      empty={false}
      pagination={<CustomPagination />}
      exporter={false}
      aside={<TareFilter />}
      sort={{ field: 'description', order: 'ASC' }}
    >
      <Datagrid>
        <TextField source="barcode" label="Штрихкод" />
        <TextField source="description" label="Описание" />
        <BooleanField source="isActive" label="Активен" />
        <ReferenceField reference="categories" source="categoryId" label="Категория">
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  )
}
