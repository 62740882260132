import {
  BooleanInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import classes from '../admin-users/AdminUsers.module.sass'
import { ListActions } from './components/listActions/ListActions'
import { useState } from 'react'
import { Aside } from './components/aside/Aside'

export const CreateTare = () => {
  const navigate = useNavigate()
  const [showAside, setShowAside] = useState(false)
  const toggleAside = () => {
    setShowAside((prev) => !prev)
  }
  return (
    <Create
      title="Создать тару"
      redirect="list"
      actions={<ListActions onClickHandler={toggleAside} />}
      aside={showAside ? <Aside closeAside={toggleAside} /> : undefined}
    >
      <SimpleForm>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(-1)}
        >
          Назад
        </Button>
        <TextInput
          source="barcode"
          label="Штрихкод"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
          className={classes.DataFieldContainer}
        />
        <TextInput
          source="description"
          label="Описание"
          className={classes.DataFieldContainer}
          parse={(value: string) => (value ? value : '')}
        />
        <BooleanInput source="isActive" label="Активен" className={classes.DataFieldContainer} />
        <ReferenceInput reference="categories" source="categoryId" perPage={1000} isRequired={true}>
          <SelectInput optionText="name" optionValue="guid" label="Категория" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}
