import { Card, CardContent } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import axios from 'axios'
import inMemoryJWT from '../../../../auth/inMemoryJWT'
import config from '../../../../constants/config'
import classes from './Aside.module.sass'
import { Oval } from 'react-loader-spinner'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

type Props = {
  closeAside: () => void
}

interface UploadFileResponse {
  totalCount: number
  accepted: number
  rejected: number
}

export const Aside = ({ closeAside }: Props) => {
  const [wipe, setWipe] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [uploadResult, setUploadResult] = useState<UploadFileResponse | null>(null)

  const isUploadDisable = () => {
    return (
      !file ||
      !(
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) ||
      loading
    )
  }

  const removeFile = () => {
    if (!file) return
    setFile(null)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      setFile(null)
      return
    }
    setFile(event.target.files[0])
    console.log('file type', event.target.files[0].type)
  }

  const resetForm = () => {
    setFile(null)
    setLoading(false)
    setWipe(false)
  }

  const upload = async () => {
    try {
      if (!file) return
      setLoading(true)
      setShowPopup(true)
      await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))
      const formData = new FormData()
      formData.append('file', file)
      formData.append('wipe', wipe.toString())

      const token = inMemoryJWT.getToken()
      const response = await axios.post<UploadFileResponse>(
        `${config.apiUrl}/tare/upload-file`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 10000,
        },
      )
      console.log('data: ', response.data)
      setUploadResult(response.data)
      resetForm()
    } catch (error) {
      resetForm()
      setHasError(true)
      console.log('upload error: ', error)
    }
  }

  return (
    <Card sx={{ ml: 1, mt: 6, width: 400, position: 'relative' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Импорт XLS
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Пример данных в таблице
        </Typography>
        <Typography variant="body2" sx={{ mb: 3 }}>
          Таблица должна быть без заголовков. Допускается наличие нескольких листов. 3 столбца, в
          первом штрихкод, во втором описание, в третьем название категории.
        </Typography>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}></TableCell>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  A
                </TableCell>
                <TableCell align="right">B</TableCell>
                <TableCell align="right">C</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  1
                </TableCell>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  4603934000236
                </TableCell>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  тара 1
                </TableCell>
                <TableCell align="right">категория 1</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  2
                </TableCell>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  4603900000237
                </TableCell>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  тара 2
                </TableCell>
                <TableCell align="right">категория 2</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  3
                </TableCell>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  4605734000238
                </TableCell>
                <TableCell align="right" sx={{ borderRight: '1px solid #eeeeee' }}>
                  тара 3
                </TableCell>
                <TableCell align="right">категория 3</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          sx={{ mb: 3 }}
          disabled={loading}
        >
          Выбрать файл
          <VisuallyHiddenInput type="file" onChange={handleFileChange} />
        </Button>
        {file && (
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: 3 }}>
            <Typography variant="body2" color="blue">
              {file.name}
            </Typography>
            <IconButton aria-label="delete" size="small" onClick={removeFile} disabled={loading}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
        {file &&
          file.type !== 'application/vnd.ms-excel' &&
          file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
            <Typography variant="body2" color="red" sx={{ mb: 3 }}>
              недопустимый формат файла
            </Typography>
          )}
        <FormControlLabel
          control={<Switch value={wipe} onChange={(e) => setWipe(e.target.checked)} />}
          label="Перезаписать"
          style={{ display: 'none' }}
        />
        <Typography variant="body2" sx={{ mb: 3 }} style={{ display: 'none' }}>
          {wipe ? 'текущие данные будут заменены' : 'данные будут добавлены к уже имеющимся'}
        </Typography>
        <Button variant="contained" disabled={isUploadDisable()} onClick={upload}>
          Загрузить
        </Button>
      </CardContent>
      {showPopup && (
        <div className={classes.PopupContainer}>
          {loading ? (
            <div className={classes.PopupWrapper}>
              <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>
                Обработка
              </Typography>
              <Oval
                visible={true}
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <div className={classes.PopupWrapper}>
              <Typography
                variant="body1"
                sx={{ textAlign: 'center', mb: 2, color: hasError ? 'red' : 'green' }}
              >
                {hasError ? (
                  'Во время записи данных произошла ошибка'
                ) : uploadResult ? (
                  <>
                    <span>Успешно</span>
                    <br />
                    <span>всего записей: {uploadResult.totalCount}</span>
                    <br />
                    <span>приято: {uploadResult.accepted}</span>
                    <br />
                    <span>отклонено: {uploadResult.rejected}</span>
                  </>
                ) : (
                  'Данные успешно записаны'
                )}
              </Typography>
              {hasError && (
                <Typography variant="body2" sx={{ textAlign: 'center', mb: 2 }}>
                  Возможные причины: файл слишком большой, имеются одинаковые штрихкоды.
                </Typography>
              )}
              <Button variant="contained" onClick={closeAside}>
                Закрыть
              </Button>
            </div>
          )}
        </div>
      )}
    </Card>
  )
}
