import { UsersList } from './UsersList'
import { CreateUser } from './CreateUser'
import { EditUser } from './EditUser'

const pages = {
  list: UsersList,
  create: CreateUser,
  edit: EditUser,
}

export default pages
