import { BooleanField, Datagrid, DateField, List, TextField } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'

export const CategoriesList = () => (
  <List title="Список категорий" empty={false} pagination={<CustomPagination />} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="category" label="Идентификатор" />
      <TextField source="categoryName" label="Название" />
      <TextField source="name" label="Описание" />
      <TextField source="guid" label="Id" />
      <TextField source="point" label="Баллы" />
      <TextField source="unit" label="Ед. измерения" />
      <TextField source="unitName" label="Ед. изм. название" />
      <BooleanField source="isInt" label="Целое число" />
      <BooleanField source="active" label="Активно" />
      <DateField source="pointLastUpdate" showTime={true} label="Дата обновления" />
    </Datagrid>
  </List>
)
