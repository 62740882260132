import config from '../constants/config'
import jsonServerProvider from 'ra-data-json-server'
import { fetchUtils } from 'react-admin'
import inMemoryJWT from '../auth/inMemoryJWT'

const fetchJson = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = inMemoryJWT.getToken()
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchUtils.fetchJson(url, options)
  } else {
    inMemoryJWT.setRefreshTokenEndpoint(`${config.authUrl}/admin-users/refresh`)
    return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
      if (gotFreshToken) {
        options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`)
      }
      return fetchUtils.fetchJson(url, options)
    })
  }
}

const dataProvider = jsonServerProvider(`${config.apiUrl}`, fetchJson)

const myDataProvider = () => {
  return {
    ...dataProvider,
  }
}

export default myDataProvider
