import { Menu } from 'react-admin'
import GroupIcon from '@mui/icons-material/Group'
import DevicesIcon from '@mui/icons-material/Devices'
import AppsIcon from '@mui/icons-material/Apps'
import WebStoriesIcon from '@mui/icons-material/WebStories'
import CropFreeIcon from '@mui/icons-material/CropFree'

export const CustomMenu = () => (
  <Menu>
    {/* <Menu.DashboardItem /> */}
    <Menu.Item to="/categories" primaryText="Категории" leftIcon={<WebStoriesIcon />} />
    <Menu.Item to="/tare" primaryText="Тара" leftIcon={<AppsIcon />} />
    <Menu.Item to="/fandomat-devices" primaryText="Устройства" leftIcon={<DevicesIcon />} />
    <Menu.Item to="/admin-users" primaryText="Администраторы" leftIcon={<GroupIcon />} />
    <Menu.Item to="/unknown-barcodes" primaryText="Штрихкоды" leftIcon={<CropFreeIcon />} />
  </Menu>
)
