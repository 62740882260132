import { CreateFandomatDevice } from './CreateFandomatDevice'
import { EditFandomatDevice } from './EditFandomatDevice'
import { FandomatDevicesList } from './FandomatDeviecesList'

const pages = {
  list: FandomatDevicesList,
  create: CreateFandomatDevice,
  edit: EditFandomatDevice,
}

export default pages
