import { Datagrid, EditButton, List, TextField } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'

export const FandomatDevicesList = () => (
  <List title="Список устройств" empty={false} pagination={<CustomPagination />} exporter={false}>
    <Datagrid>
      <TextField source="deviceId" label="Id устройства" />
      <TextField source="deviceName" label="Название" />
      <TextField source="login" label="Логин" />
      <EditButton />
    </Datagrid>
  </List>
)
