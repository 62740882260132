import { Button } from 'react-admin'
import AddchartIcon from '@mui/icons-material/Addchart'

type Props = {
  onClickHandler: () => void
}

export const ImportXLSButton = ({ onClickHandler }: Props) => (
  <Button label="Импорт xls" startIcon={<AddchartIcon />} onClick={onClickHandler} />
)
