import { CreateButton, TopToolbar } from 'react-admin'
import { ImportXLSButton } from './components/ImportXLSButton'

type Props = {
  onClickHandler: () => void
}

export const ListActions = ({ onClickHandler }: Props) => (
  <TopToolbar>
    {/* <CreateButton /> */}
    <ImportXLSButton onClickHandler={onClickHandler} />
  </TopToolbar>
)
