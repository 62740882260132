import './App.css'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import russianMessages from 'ra-language-russian'
import { Admin, Resource } from 'react-admin'
import myDataProvider from './dataProvider/myDataProvider'
import authProvider from './auth/authProvider'
import { CustomLayout } from './layout/CustomLayout'
import adminUsers from './pages/admin-users'
import fandomatDevises from './pages/fandomat-devices'
import categories from './pages/categories'
import tare from './pages/tare'
import unknownBarcodes from './pages/unknown-barcodes'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

function App() {
  const dataProvider = myDataProvider()

  return (
    <Admin
      title="Панель управления"
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={CustomLayout}
      disableTelemetry={true}
    >
      <Resource
        name="admin-users"
        list={adminUsers.list}
        create={adminUsers.create}
        edit={adminUsers.edit}
      />
      <Resource
        name="fandomat-devices"
        list={fandomatDevises.list}
        create={fandomatDevises.create}
        edit={fandomatDevises.edit}
      />
      <Resource name="categories" list={categories.list} />
      <Resource name="tare" list={tare.list} create={tare.create} edit={tare.edit} />
      <Resource name="unknown-barcodes" list={unknownBarcodes.list} />
    </Admin>
  )
}

export default App
