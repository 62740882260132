import { Datagrid, DateField, List, SearchInput, TextField } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'

export const UnknownBarcodesList = () => {
  return (
    <List
      title="Неопознанные штрихкоды"
      empty={false}
      pagination={<CustomPagination />}
      exporter={false}
      // aside={<TareFilter />}
      sort={{ field: 'timestamp', order: 'DESC' }}
      filters={[<SearchInput source="q" alwaysOn />]}
    >
      <Datagrid>
        <TextField source="barcode" label="Штрихкод" />
        <DateField source="timestamp" label="Дата добавления" showTime={true} />
      </Datagrid>
    </List>
  )
}
