import { CreateTare } from './CreateTare'
import { EditTare } from './EditTare'
import { TareList } from './TareList'

const pages = {
  list: TareList,
  create: CreateTare,
  edit: EditTare,
}

export default pages
